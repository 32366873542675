@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

:root {
  /*Convert env to variables to make them work with calc()*/
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.04em;
  background: #f5f5f5;
}

button {
  display: inline-block;
  vertical-align: middle;
  padding: 0.85em 1em;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out,
    -webkit-backdrop-filter 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out,
    backdrop-filter 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out,
    backdrop-filter 0.25s ease-out, -webkit-backdrop-filter 0.25s ease-out;
  font-family: inherit;
  font-size: 1.2rem;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.7);
}

button.blue {
  background-color: #3c95cc;
  color: #ffffff;
}

a {
  color: #ffffff;
}

.spec-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  transition: font-size 0.25s ease-out;
  gap: .5em;
}

.spec-item {
  .spec-key {
    font-weight: 700;
  }
  .spec-value {
    font-weight: 300;
  }
}

.spec-icon {
  width: 2.5em;
  height: 2.5em;

  @media (max-width: 576px), (max-height: 576px) {
    width: 1.5em;
    height: 1.5em;
  }
}
.spec-icon svg {
  width: 100%;
  height: 100%;
}

.product-info {
  position: absolute;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  bottom: calc(10em + var(--safe-area-inset-top));
  right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1em;
  touch-action: none;
  color: #fff;

  h2 {
    @media (max-width: 576px), (max-height: 576px) {
      font-size: 1em;
    }
  }
}

.close-button {
  position: fixed;
  bottom: calc(2em + var(--safe-area-inset-top));
  right: calc(2em + var(--safe-area-inset-right));
  width: 3.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.25s ease-out;

  &:hover {
    color: rgb(149, 151, 151);
  }
}

.floor-picker-panel {
  width: 10em;
  right: -10em;
  height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  color: white;
  font-size: 1em;
  .floor-picker-item-container {
    width: 8em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    margin: 1em;
    .floor-picker-item {
      width: 8em;
      height: 8em;
      position: relative;
      cursor: pointer;
      .floor-picker-thumbnail {
        display: block;
        position: absolute;
        inset: 0;
        width: 8em;
        height: 8em;
        object-fit: cover;
      }
      .floor-picker-label {
        position: absolute;
        inset: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0.2em;
        background: rgba(0, 0, 0, 0.2);
        text-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
        font-size: 0.9em;
        transition: opacity 0.25s ease-out;
        opacity: 0;
      }
      &:hover .floor-picker-label {
        opacity: 1;
      }
    }
  }
}

.panel-button {
  position: absolute;
  bottom: var(--safe-area-inset-bottom);
  background-color: #333;
  border: 5px solid #fff;
  cursor: pointer;
  transition: border 0.25s ease-out, width 0.25s ease-out, height 0.25s ease-out;
  color: white;
  width: 8rem;
  height: 8rem;

  .panel-button-inner {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.2em;
    font-size: 0.8em;
    .panel-button-inner-box {
      margin: 0.4em;
      padding: 0.1em 0.4em;
      border: 1px solid #fff;
    }
  }
  &:hover {
    border: 5px solid rgb(149, 151, 151);
  }
}

.scene-buttons-container {
  position: absolute;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  top: calc(3em + var(--safe-area-inset-top));
  left: 3em;
  width: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 2em;
  touch-action: none;
  .scene-button {
    width: 2.5em;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
    &:hover {
      opacity: 0.8;
    }
  }
}

/* Screen width lower than 576px or height lower than 420px */
@media (max-width: 575px), (max-height: 419px) {
  .floor-picker-panel {
    font-size: 0.75em;
  }

  .panel-button {
    width: 5.6rem;
    height: 5.6rem;

    .panel-button-inner {
      .panel-button-inner-box {
        border: 0 none;
      }
    }


  }
}

@media (max-height: 600px) {
  .product-info {
    font-size: 0.6em;
    top: 1em;
    bottom: auto;
  }
}

/* Touch device */
@media (hover: none) {
  .floor-picker-label {
    opacity: 1 !important;
    justify-content: flex-end !important;
    padding: 0 !important;
    color: RGBA(255, 255, 255, 0.8) !important;
    background: none !important;
  }
}
